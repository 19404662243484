import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [0];

export const dictionary = {
		"/": [14],
		"/(apis)/api-aws": [~19],
		"/(apis)/api-notifications/[...passthrough]": [~20],
		"/(apis)/api-users/[...passthrough]": [~21],
		"/(apis)/api/auth/logout": [~16],
		"/(apis)/api/auth/microsoft/login": [~17],
		"/(apis)/api/notification-token": [~18],
		"/(apis)/api/[...passthrough]": [~15],
		"/auth/2fa/setup": [56,[11]],
		"/auth/2fa/verify": [57,[11]],
		"/auth/password/reset/confirm/[uid]/[token]": [58,[11]],
		"/auth/registration/account-confirm-email/[key]": [59,[11]],
		"/auth/reset-password": [60,[11]],
		"/(controls)/controls/[...id]": [22],
		"/(devices-hub)/devices-hub": [23,[2]],
		"/(devices-hub)/devices-hub/install": [24,[2]],
		"/(devices-hub)/devices-hub/replace": [25,[2]],
		"/(onboarding-hub)/email": [48,[9]],
		"/help": [61,[12]],
		"/(hub)/insights": [26,[3]],
		"/(internal-tools)/internal/plug-renders": [45],
		"/(internal-tools)/internal/ruleset-mass-deploy/[id_locationgroup]": [46],
		"/(intranet)/intranet": [47,[8]],
		"/login": [62],
		"/login/register": [63],
		"/login/register/resend-email": [64],
		"/login/reset-password": [65],
		"/(onboarding-hub)/onboarding-hub": [49,[9]],
		"/(onboarding-hub)/onboarding-hub/email/wifi-details": [~51],
		"/(onboarding-hub)/onboarding-hub/[id_locationgroup]/orders/[id_order]": [50,[9]],
		"/preferences": [66,[13]],
		"/(hub)/profile": [27,[3]],
		"/(redirect-service)/redirect": [~52],
		"/(hub)/resources/locations": [28,[3,4]],
		"/(hub)/resources/locations/[id_locationgroup=integer]": [29,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/api-keys": [30,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/asset-register": [31,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups": [32,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups/[attributeName=string]": [33,[3,4,5,6]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups/[attributeName=string]/[attributeValue=string]": [34,[3,4,5,6,7]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/location-management": [35,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/off-times-combined": [36,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/rulesets": [37,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/status-check": [38,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/user-roles": [39,[3,4,5]],
		"/(hub)/resources/organisations": [40,[3]],
		"/(hub)/resources/organisations/[id_org=integer]": [41,[3]],
		"/(hub)/resources/roles": [42,[3]],
		"/(hub)/resources/users": [43,[3]],
		"/(surveys-hub)/surveys-hub": [53,[10]],
		"/(surveys-hub)/surveys-hub/[id_locationgroup]": [54,[10]],
		"/(hub)/user-profiles/[id_user_profile=integer]": [44,[3]],
		"/[...path]": [55]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';